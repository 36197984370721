
global.$ = global.jQuery = require('jquery');


//require('jquery-ui/ui/effects/effect-slide.js');
//require('jquery-ui/ui/widgets/datepicker.js');
//require('jquery-ui/ui/i18n/datepicker-hr.js');

window.bootstrap = require('bootstrap');

require('@popperjs/core');
//require('szmigiel-meanmenu/jquery.meanmenu.min');
//require('jquery-nice-select/js/jquery.nice-select')

//require('owl.carousel');
require('magnific-popup');

require('jquery-form');
window.Swal = require('sweetalert2');
//require('jquery-zoom');
//require('swiper/bundle');
//require('select2');
//require('slick-carousel');
//require('choices.js');

//require('waypoints/lib/jquery.waypoints.min.js');
//require('jcarousel');
//require('bootstrap-star-rating');
require('./_notifications.js');
require('./_forms.js');
require('./main.js');
require('./_cookies.js');
//require('./_search.js');
//require('./_swiper.js');

/*
try {
    require('./http.js');
    require('./newsletter.js');
    require('./cookies.js');
} catch (e) {
    console.log(e);
}
*/



