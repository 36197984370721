$(document).ready(function() {
	$('.popup-gallery').magnificPopup({
		delegate: 'a',
		type: 'image',
		tLoading: 'Učitavanje #%curr%...',
		mainClass: 'mfp-img-mobile',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1]
		},
		image: {
			tError: '<a href="%url%">Sliku #%curr%</a> nije moguce ucitati.',
		}
	});

	$(document).on("click", ".glo-header-top-right .nice-select .option:not(.disabled)", function (t) {

		var s = $(this),
		n = s.closest(".nice-select");

		window.location = s.data("value");

		console.log(s.data("value"));
	});
  // 03. Sidebar Js
  $('.glo-sidebar-action').on('click', function () {
      $('.glo-side-info').addClass('glo-side-info-open')
      $('.offcanvas-overlay').addClass('offcanvas-overlay-open')
  });
  $('.glo-side-info-close').on('click', function () {
      $('.glo-side-info').removeClass('glo-side-info-open')
      $('.offcanvas-overlay').removeClass('offcanvas-overlay-open')
  });
  $('.offcanvas-overlay').on('click', function () {
      $('.glo-side-info').removeClass('glo-side-info-open')
      $('.offcanvas-overlay').removeClass('offcanvas-overlay-open')
  });




});